//import _block from '../core/_schema/_block'
import photoSchema from '../core/_schema/photo'
import fileSchema from '../core/_schema/file'


const schema = {
  endpoint: '/mediatekas',
  resource: {
    definition: 'Mediateka-mediateka:read',
    properties: {
      translations: {
        type: 'translation',
        properties: {
          title: {
            //validate: ['required'],
          },
          description: {
            type: 'tinymce',
          },
          transcription: {
            type: 'tinymce',
          },
          alt: {},
          slug: {},
        },
      },

//      mediatekaCollection: {
//        description: 'T_MODULE_MEDIATEKA_COLLECTIONS',
//        validate: ['required'],
//        type: 'resource',
//        titleAccessor: `translations.${process.env.REACT_APP_LOCALE}.title`,
//        //endpoint: `${articlesSchema.endpoint}?order[ord]=desc&pagination=false`,
//        endpoint: `/mediateka_collections?order[ord]=desc&pagination=false`,
//      },
      isHomepagePromoted:{
        type: 'boolean',
      },
      skipPhotoUpdate:{
        type: 'boolean',
      },
      photo: {
        type: 'image',
        description: 'T_GENERAL_PHOTO',
        endpoint: photoSchema.endpoint.single,
        //endpointMultiple: photoSchema.endpoint.multiple,
        thumbs: {
          endpoint: '/mediateka_thumbs',
        },
        validate: ['maxSize'],
      },

      relatedKnowledges: {
        description: 'T_GENERAL_RELATED_KNOWLEDGES',
        validate: [],
        type: 'multipleResource',
        titleAccessor: `translations.${process.env.REACT_APP_LOCALE}.title`,
        //endpoint: `${exhibitionSchema.endpoint}?order[ord]=desc&pagination=false`,
        endpoint: `/knowledges?order[ord]=desc&pagination=false`,
      },
    },
  },
  
}

export default schema
