export const youtube = (photoEndpoint, thumbEndpoint) => ({
  name: 'youtube',
  label: 'T_GENERAL_BLOCK_TYPE_YOUTUBE',
  properties: {
    translations: {
      type: 'translation',
      properties: {
        title: {
            type: 'textarea',
        },
        subtitle: {},
        text: {
          type: 'tinymce',
        },
        photoAlt: {
            type: 'string'
        },
        anchorTitle: {},
        youtube: {},
      },
    },
    photo: {
      type: 'image',
      description: 'T_GENERAL_VIDEO_MASK',
      endpoint: photoEndpoint,
      thumbs: thumbEndpoint && {
        endpoint: thumbEndpoint,
      },
      validate: ['maxSize'],
    },
    buttonLink: {
      description: 'T_GENERAL_AUDIO_DESC',
    },
    //youtube: {},
  },
})
