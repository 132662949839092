
const schema = {
  endpoint: '/configs',
  resource: {
    definition: 'Config-config:read',
    properties: {

      translations: {
        type: 'translation',
        properties: {

          metaTitle: {
            validate: ['required'],
          },
          metaDescription: {
          },
          metaKeywords: {
          },
        },
      },
      headerScripts: {
        type: 'textarea'
      },
      facebook: {
        description: 'T_CONFIG_FACEBOOK',
        validate: [],
      },
      instagram: {
        description: 'T_CONFIG_INSTAGRAM',
        validate: [],
      },
      twitter: {
        description: 'T_CONFIG_TWITTER',
        validate: [],
      },
      youtube: {
        description: 'T_CONFIG_YOUTUBE',
        validate: [],
      },
      spotify: {
        description: 'T_CONFIG_SPOTIFY',
        validate: [],
      },
      tikTok: {
        description: 'T_CONFIG_TIK_TOK',
        validate: [],
      },
      linkedIn: {
        description: 'T_CONFIG_LINKED_IN',
        validate: [],
      },
      podcastYoutubeUrl: {
        description: 'T_CONFIG_PODCAST_YOUTUBE_URL',
        validate: [],
      },
      podcastSpotifyUrl: {
        description: 'T_CONFIG_PODCAST_SPOTIFY_URL',
        validate: [],
      },
//      searchReindex: {
//        type: 'boolean',
//        label: 'Reindexacja wyszukiwarki',
//      },
      frameColorEvents: {
        fullWidth: false,
        type: 'choice',
        choices: {
          '#E02420': 'czerwony',
          '#A89171': 'złoty',
          '#A8A8A8': 'szary',
        },
        validate: ['required'],
      },
      frameColorExhibitions:{
        fullWidth: false,
        type: 'choice',
        choices: {
          '#A89171': 'złoty',
          '#D4D4D4': 'szary',
        },
        validate: ['required'],
      },
      
      newsletterApiKey: {
        description: 'T_CONFIG_NEWSLETTER_API_KEY',
        validate: [],
      },
      newsletterCampaignId: {
        description: 'T_CONFIG_NEWSLETTER_CAMPAIGN_ID',
        validate: [],
      },
      developerForceIps: {
        type: 'textarea'
      },
      
      

//      type: {
//        type: 'media[type]',
//        fullWidth: false,
//      },
//      youtube: {
//        type: 'media[youtube]',
//        description: 'T_GENERAL_YOUTUBE_CODE',
//      },
//      photo: {
//        type: 'media[image]',
//        description: 'T_GENERAL_MEDIA_PHOTO',
//        endpoint: photoSchema.endpoint.single,
//        endpointYoutube: photoSchema.endpoint.youtube,
//        endpointMultiple: photoSchema.endpoint.multiple,
//        thumbs: {
//          endpoint: '/config_thumbs',
//        },
//        validate: ['maxSize'],
//        ytProperty: 'youtube',
//      },
    },
  },
}

export default schema
