const schema = {
  endpoint: '/event_dates',
  resource: {
    definition: 'EventDate-eventDate:read',
    properties: {
      translations: {
        type: 'translation',
        properties: {
          // city: {},
          place: {},
          ticketButtonLabel: {},
          ticketPriceInfo: {
            type: 'textarea',
          },
        },
      },
      ticketLink: {
        fullWidth: true,
      },
      dateTimeFrom: {
        type: 'date',
        fullWidth: false,
        validate: ['required'],
      },
      timeFrom: {
        type: 'time',
        fullWidth: false,
        validate: [],
      },
      dateTimeTo: {
        type: 'date',
        fullWidth: false,
        validate: [],
      },
      timeTo: {
        type: 'time',
        fullWidth: false,
        validate: [],
      },

      ticketPrice: {},
    },
  },
}

export default schema
