
const schema = {
  endpoint: '/configs',
  resource: {
    definition: 'Config-config:read',
    properties: {

//      translations: {
//        type: 'translation',
//        properties: {
//
//        },
//      },
      
      titleTomorrowsPatriotismContact1: {
        type: 'title',
        description: 'Osoba kontaktowa 1',
      },
      tomorrowsPatriotismContact1FirstLastName: {},
      tomorrowsPatriotismContact1Phone: {},
      tomorrowsPatriotismContact1Email: {},
      
      titleTomorrowsPatriotismContact2: {
        type: 'title',
        description: 'Osoba kontaktowa 2',
      },
      tomorrowsPatriotismContact2FirstLastName: {},
      tomorrowsPatriotismContact2Phone: {},
      tomorrowsPatriotismContact2Email: {},
      
      titleTomorrowsPatriotismContact3: {
        type: 'title',
        description: 'Osoba kontaktowa 3',
      },
      tomorrowsPatriotismContact3FirstLastName: {},
      tomorrowsPatriotismContact3Phone: {},
      tomorrowsPatriotismContact3Email: {},

    },
  },
}

export default schema
