export const addOpenformProperties = (
  isOpenform,
  isSuperAdmin,
  properties,
  resource,
  schema
) => {
  let modifiedProperties = properties

  if (isOpenform) {
    modifiedProperties = {
      ...modifiedProperties,
      idName: {
        type: 'string',
        fullWidth: false,
        description: {
          text: 'T_GENERAL_OPENFORM_PAGE_IDNAME',
          color: '#cc0000',
        },
      },
//      isStatable: {
//        type: 'boolean',
//        description: {
//          text: 'T_GENERAL_OPENFORM_PAGE_IS_STATABLE',
//          color: '#cc0000',
//        },
//        defaultValue: true,
//      },
//      isEditable: {
//        type: 'boolean',
//        description: {
//          text: 'T_GENERAL_OPENFORM_PAGE_IS_EDITABLE',
//          color: '#cc0000',
//        },
//        defaultValue: true,
//      },
//      isDeletable: {
//        type: 'boolean',
//        description: {
//          text: 'T_GENERAL_OPENFORM_PAGE_IS_DELETABLE',
//          color: '#cc0000',
//        },
//        defaultValue: true,
//      },
//      isSubPageable: {
//        type: 'boolean',
//        description: {
//          text: 'T_GENERAL_OPENFORM_PAGE_IS_SUBPAGEABLE',
//          color: '#cc0000',
//        },
//        defaultValue: true,
//      },
    }
  }
  
//  if (isSuperAdmin) {
//    modifiedProperties = {
//      ...modifiedProperties,
//      mainMenu: {
//        type: 'boolean',
//        description: {
//          text: 'T_MODULE_PAGES_SHOW_IN_MAIN_MENU',
//          color: '#FF7200',
//        },
//        defaultValue: false,
//      },
//      additionalMenu: {
//        type: 'boolean',
//        description: {
//          text: 'T_MODULE_PAGES_SHOW_IN_ADDITIONAL_MENU',
//          color: '#FF7200',
//        },
//        defaultValue: false,
//      },
//      footerMenu: {
//        type: 'boolean',
//        description: {
//          text: 'T_MODULE_PAGES_SHOW_IN_FOOTER_MENU',
//          color: '#FF7200',
//        },
//        defaultValue: false,
//      },
//    }
//  }
//
//  if (!isOpenform && resource && !resource.isStatable) {
//    delete modifiedProperties.stat
//  }

  return modifiedProperties
}
