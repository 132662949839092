import { boolean } from 'core/_helpers/boolean'
import home from 'core/pages/Home/routes'
import admins from 'core/pages/Admins/routes'
import adminGroups from 'core/pages/AdminGroups/routes'
import loginEntries from 'core/pages/LoginEntries/routes'
import registryEntries from 'core/pages/RegistryEntries/routes'
import config from 'core/pages/Config/routes'
import profile from 'core/pages/Profile/routes'
import messenger from 'core/pages/Messenger/routes'
import pages from 'core/pages/Pages/routes'
import translatorEntries from 'core/pages/TranslatorEntries/routes'
import packageFiles from 'core/pages/PackageFiles/routes'
import packageGalleries from 'core/pages/PackageGalleries/routes'
import packageGalleryItems from 'core/pages/PackageGalleryItems/routes'
import packageFaqs from 'core/pages/PackageFaqs/routes'
import packageFaqItems from 'core/pages/PackageFaqItems/routes'
import packageForms from 'core/pages/PackageForms/routes'
import packageFormItems from 'core/pages/PackageFormItems/routes'


import antiques from 'pages/Antiques/routes'
import antiqueSlides from 'pages/AntiqueSlides/routes'
import articles from 'pages/Articles/routes'
import articleSlides from 'pages/ArticleSlides/routes'
import calendars from 'pages/Calendars/routes'
import calendarSlides from 'pages/CalendarSlides/routes'
import banners from 'pages/Banners/routes'
import configVolunteering from 'pages/ConfigVolunteering/routes'
import configTomorrowsPatriotism from 'pages/ConfigTomorrowsPatriotism/routes'
import configGuide from 'pages/ConfigGuide/routes'
import configContact from 'pages/ConfigContact/routes'
import cooperationMessages from 'pages/CooperationMessages/routes'
import cooperationTypes from 'pages/CooperationTypes/routes'
import coordinators from 'pages/Coordinators/routes'
import educationActivities from 'pages/EducationActivities/routes'
import educationTargets from 'pages/EducationTargets/routes'
import educationAgeGroups from 'pages/EducationAgeGroups/routes'
import events from 'pages/Events/routes'
import eventTypes from 'pages/EventTypes/routes'
import eventAgeGroups from 'pages/EventAgeGroups/routes'
import eventDates from 'pages/EventDates/routes'
import exhibitions from 'pages/Exhibitions/routes'
import exhibitionTypes from 'pages/ExhibitionTypes/routes'
import guides from 'pages/Guides/routes'
import guideLanguages from 'pages/GuideLanguages/routes'
import guidePaths from 'pages/GuidePaths/routes'

import jobOffers from 'pages/JobOffers/routes'

import openingHours from 'pages/OpeningHours/routes'
import holidays from 'pages/Holidays/routes'

import knowledges from 'pages/Knowledges/routes'
import knowledgeSlides from 'pages/KnowledgeSlides/routes'
import knowledgePublicationTypes from 'pages/KnowledgePublicationTypes/routes'
import knowledgeTargets from 'pages/KnowledgeTargets/routes'
import knowledgeEpochs from 'pages/KnowledgeEpochs/routes'
import knowledgeGenres from 'pages/KnowledgeGenres/routes'

import logoKits from 'pages/LogoKits/routes'
import logoKitItems from 'pages/LogoKitItems/routes'

import menus from 'pages/Menus/routes'
import menuItems from 'pages/MenuItems/routes'

import procurements from 'pages/Procurements/routes'
import procurementSlides from 'pages/ProcurementSlides/routes'

import saleOffers from 'pages/SaleOffers/routes'
import saleOfferSlides from 'pages/SaleOfferSlides/routes'

import offerInquiries from 'pages/OfferInquiries/routes'
import offerInquirySlides from 'pages/OfferInquirySlides/routes'

import marketAdvices from 'pages/MarketAdvices/routes'
import marketAdviceSlides from 'pages/MarketAdviceSlides/routes'

import mediatekas from 'pages/Mediatekas/routes'
import mediatekaTags from 'pages/MediatekaTags/routes'
import mediatekaSlides from 'pages/MediatekaSlides/routes'
import mediatekaCollections from 'pages/MediatekaCollections/routes'

import publicTenders from 'pages/PublicTenders/routes'
import publicTenderSlides from 'pages/PublicTenderSlides/routes'

import quizzes from 'pages/Quizzes/routes'
import quizQuestions from 'pages/QuizQuestions/routes'
import quizAnswers from 'pages/QuizAnswers/routes'

import sponsorFirms from 'pages/SponsorFirms/routes'
import sponsorPersons from 'pages/SponsorPersons/routes'

import teamItems from 'pages/TeamItems/routes'
import teamSections from 'pages/TeamSections/routes'

import visitInfos from 'pages/VisitInfos/routes'
import visitPlanMessages from 'pages/VisitPlanMessages/routes'


import zonks from 'pages/Zonks/routes'

const routes = () =>
  [
    ...getArray(home()),
    ...getArray(pages()),
    
    ...getArray(packageFiles()),
    ...getArray(packageGalleries()),
    ...getArray(packageGalleryItems()),
    ...getArray(packageFaqs()),
    ...getArray(packageFaqItems()),
    ...getArray(packageForms()),
    ...getArray(packageFormItems()),
    ...getArray(admins()),
    ...getArray(adminGroups()),
    ...getArray(loginEntries()),
    ...getArray(registryEntries()),
    ...getArray(translatorEntries()),
    ...getArray(config()),
    ...getArray(profile()),

    
    
    ...getArray(antiques()),
    ...getArray(antiqueSlides()),
    ...getArray(articles()),
    ...getArray(articleSlides()),
    ...getArray(calendars()),
    ...getArray(calendarSlides()),
    ...getArray(banners()),
    ...getArray(configVolunteering()),
    ...getArray(configTomorrowsPatriotism()),
    ...getArray(configGuide()),
    ...getArray(configContact()),
    ...getArray(cooperationMessages()),
    ...getArray(cooperationTypes()),
    ...getArray(coordinators()),
    ...getArray(educationTargets()),
    ...getArray(educationAgeGroups()),
    ...getArray(educationActivities()),
    ...getArray(events()),
    ...getArray(eventTypes()),
    ...getArray(eventDates()),
    ...getArray(eventAgeGroups()),
    ...getArray(exhibitions()),
    ...getArray(exhibitionTypes()),
    ...getArray(guides()),
    ...getArray(guideLanguages()),
    ...getArray(guidePaths()),
    
    ...getArray(jobOffers()),
    
    ...getArray(openingHours()),
    ...getArray(holidays()),
    
    ...getArray(knowledges()),
    ...getArray(knowledgeSlides()),
    ...getArray(knowledgePublicationTypes()),
    ...getArray(knowledgeTargets()),
    ...getArray(knowledgeEpochs()),
    ...getArray(knowledgeGenres()),
    
    ...getArray(logoKits()),
    ...getArray(logoKitItems()),    
    
    ...getArray(menus()),
    ...getArray(menuItems()),    
   
    ...getArray(offerInquiries()),
    ...getArray(offerInquirySlides()),

    ...getArray(marketAdvices()),
    ...getArray(marketAdviceSlides()),
    
    ...getArray(mediatekas()),
    ...getArray(mediatekaTags()),
    ...getArray(mediatekaSlides()),
    ...getArray(mediatekaCollections()),
    
    ...getArray(publicTenders()),
    ...getArray(publicTenderSlides()),
    
    ...getArray(procurements()),
    ...getArray(procurementSlides()),
    
    ...getArray(saleOffers()),
    ...getArray(saleOfferSlides()),
    
    ...getArray(quizzes()),
    ...getArray(quizQuestions()),
    ...getArray(quizAnswers()),
    
    ...getArray(sponsorFirms()),
    ...getArray(sponsorPersons()),
    
    ...getArray(teamItems()),
    ...getArray(teamSections()),
    
    ...getArray(visitInfos()),
    ...getArray(visitPlanMessages()),
    
    ...getArray(zonks()),
    
  ].concat(
    boolean(process.env.REACT_APP_MESSENGER_SHOW) ? getArray(messenger()) : []
  )

const getArray = routes => Object.values(routes)

export default routes
