export const vimeo = (photoEndpoint, thumbEndpoint) => ({
  name: 'vimeo',
  label: 'T_GENERAL_BLOCK_TYPE_VIMEO',
  properties: {
    translations: {
      type: 'translation',
      properties: {
        title: {
            type: 'textarea',
        },
        subtitle: {},
        text: {
          type: 'tinymce',
        },
//        photoAlt: {
//            type: 'string'
//        },
//        anchorTitle: {},
        vimeo: {},
      },
    },
//    photo: {
//      type: 'image',
//      description: 'T_GENERAL_VIDEO_MASK',
//      endpoint: photoEndpoint,
//      thumbs: thumbEndpoint && {
//        endpoint: thumbEndpoint,
//      },
//      validate: ['maxSize'],
//    },
    buttonLink: {
      description: 'T_GENERAL_AUDIO_DESC',
    },
  },
})
