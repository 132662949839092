import _block from '../core/_schema/_block'
//import photoSchema from '../core/_schema/photo'

const schema = {
  endpoint: '/mediateka_tags',
  resource: {
    definition: 'MediatekaTag-mediatekaTag:read',
    properties: {

      translations: {
        type: 'translation',
        properties: {
          title: {
            //validate: ['required'],
          },
//          text: {
//            type: 'textarea',
//          },
//          slug: {},
        },
      },

    },
  },

}

export default schema
