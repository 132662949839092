//import _block from '../core/_schema/_block'
import _block from './_articleBlock'
import photoSchema from '../core/_schema/photo'
import fileSchema from '../core/_schema/file'

//import articleSchema from './article'
//import eventSchema from './event'
//import exhibitionSchema from './exhibition'

const schema = {
  endpoint: '/articles',
  preview: '/preview/articles/:id',
  resource: {
    definition: 'Article-article:read',
    properties: {
      translations: {
        type: 'translation',
        properties: {
          title: {
            type: 'textarea',
            //validate: ['required'],
          },
          text: {
            type: 'tinymce',
          },
          metaTitle: {},
          metaDescription: {
            type: 'textarea',
          },
          metaKeywords: {
            type: 'textarea',
          },
          slug: {},
          alt: {},
        },
      },
//      map: {
//        type: 'map',
//        description: 'T_GENERAL_MAP',
//        hint: 'T_GENERAL_MAP_HINT',
//      },
//      lead: {
//        type: 'textarea',
//        description: 'T_GENERAL_LEAD',
//        hint: 'T_GENERAL_LEAD_HINT',
//        validate: ['required'],
//      },
//      description: {
//        type: 'tinymce',
//        description: 'T_GENERAL_DESCRIPTION',
//        hint: 'T_GENERAL_DESCRIPTION_HINT',
//        validate: ['required'],
//      },
      date: {
        type: 'date',
        description: 'T_GENERAL_DATE',
        validate: ['required'],
      },
      plannedPublishDateTime: {
        type: 'datetime',
        description: 'T_GENERAL_PLANNED_PUBLISH_DATE_TIME',
        fullWidth: false,
        validate: ['required'],
      },
      isTomorrowsPatriotism:{
        type: 'boolean',
      },
      isPromoted:{
        type: 'boolean',
      },
      photo: {
        type: 'image',
        description: 'T_GENERAL_ARTICLE_IMAGE',
        endpoint: photoSchema.endpoint.single,
        //endpointMultiple: photoSchema.endpoint.multiple,
        thumbs: {
          endpoint: '/article_thumbs',
        },
        validate: ['maxSize'],
      },

      relatedArticles: {
        description: 'T_GENERAL_RELATED_ARTICLES',
        validate: [],
        type: 'multipleResource',
        titleAccessor: `translations.${process.env.REACT_APP_LOCALE}.title`,
        //endpoint: `${articleSchema.endpoint}?order[ord]=desc&pagination=false`,
        endpoint: `/articles?order[createdAt]=desc&pagination=false`,
      },
      relatedEvents: {
        description: 'T_GENERAL_RELATED_EVENTS',
        validate: [],
        type: 'multipleResource',
        titleAccessor: `translations.${process.env.REACT_APP_LOCALE}.title`,
        //endpoint: `${eventSchema.endpoint}?order[ord]=desc&pagination=false`,
        endpoint: `/events?order[ord]=desc&pagination=false`,
      },
      relatedExhibitions: {
        description: 'T_GENERAL_RELATED_EXHIBITIONS',
        validate: [],
        type: 'multipleResource',
        titleAccessor: `translations.${process.env.REACT_APP_LOCALE}.title`,
        //endpoint: `${exhibitionSchema.endpoint}?order[ord]=desc&pagination=false`,
        endpoint: `/exhibitions?order[ord]=desc&pagination=false`,
      },
      relatedKnowledges: {
        description: 'T_GENERAL_RELATED_KNOWLEDGES',
        validate: [],
        type: 'multipleResource',
        titleAccessor: `translations.${process.env.REACT_APP_LOCALE}.title`,
        //endpoint: `${exhibitionSchema.endpoint}?order[ord]=desc&pagination=false`,
        endpoint: `/knowledges?order[ord]=desc&pagination=false`,
      },
      
      
    },
  },
  subresources: {
    block: {
      endpoint: '/article_blocks',
      definition: 'ArticleBlock-articleBlock:read',
      contextualHint: 'how_to_edit',
      types: _block(
        photoSchema.endpoint.single,
        fileSchema.endpoint.single,
        '/article_block_sections',
        '/article_block_thumbs',
        '/article_block_section_thumbs'
      ).types,
    },
  },
  
}

export default schema
