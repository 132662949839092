import React from 'react'
import PropTypes from 'prop-types'
import { useSelector } from 'react-redux'
import { Schemable } from 'core/components/Schemable'
import { SchemaError } from 'core/_helpers/schemaError'
import { getSchemaByRef } from 'core/_helpers/getSchemaByRef'
import { prop } from 'core/_helpers/prop'

export const SchemableCollection = ({
  component,
  path,
  definitionRefPath = 'responses.200.schema.items.$ref',
  ...rest
}) => {
  return (
    <Schemable>
      <SchemaAssigner
        component={component}
        path={path}
        definitionRefPath={definitionRefPath}
        {...rest}
      />
    </Schemable>
  )
}

const SchemaAssigner = ({
  component: Component,
  path,
  definitionRefPath,
  ...rest
}) => {
  const schema = useSelector(state => state.schema)

  const endpointSchema = prop(schema, path)

  if (!schema) {
    throw new SchemaError()
  }

  const definitionSchema = getSchemaByRef(
    schema,
    prop(endpointSchema, definitionRefPath)
  )

  if (!definitionSchema) {
    throw new SchemaError('Schema by ref was not found')
  }

  return (
    <Component
      schema={endpointSchema}
      definitionSchema={definitionSchema}
      {...rest}
    />
  )
}

SchemableCollection.propTypes = {
  component: PropTypes.elementType.isRequired,
  path: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.arrayOf(PropTypes.string),
  ]).isRequired,
  definitionRefPath: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.arrayOf(PropTypes.string),
  ]),
}
