import React, { useMemo } from 'react'
import { Paper } from 'core/components/Paper'
import { CollectionHeader } from 'core/components/CollectionHeader'
import { SchemableCollection } from 'core/components/SchemableCollection'
import { CollectionTable } from 'core/components/table'
import { operations } from 'core/components/table/_columns/operations'
import { translate } from 'core/_helpers/translate'
import { publish } from 'core/components/table/_mass'
import schema from '_schema/article'
import routes from './routes'

import { PhotoCell } from 'core/components/table/cells/PhotoCell'
import { StatusCell } from 'core/components/table/cells/StatusCell'
import { DateCell } from 'core/components/table/cells/DateCell'

export const Collection = () => {
  const columns = useMemo(
    () => [
      {
        header: translate('T_GENERAL_TITLE'),
        accessor: `translations.${process.env.REACT_APP_LOCALE}.title`,
        filterName: 'translations.title',
        filterable: true,
        sortable: true,
      },
      {
        accessor: 'date',
        header: translate('T_GENERAL_DATE'),
        filterable: false,
        sortable: true,
        Cell: DateCell,
      },
      {
        accessor: 'thumbs.big',
        header: translate('T_GENERAL_PHOTO'),
        filterable: false,
        sortable: false,
        Cell: PhotoCell,
        width: '8%',
      },
      {
        header: translate('T_IS_TOMORROWS_PATRIOTISM'),
        accessor: `isTomorrowsPatriotism`,
        filterable: true,
        sortable: false,
        width: '7%',
        Cell: StatusCell,
      },
      {
        header: translate('T_IS_PROMOTED'),
        accessor: `isPromoted`,
        filterable: true,
        sortable: false,
        width: '7%',
        Cell: StatusCell,
      },
      {
        ...operations(
            `translations.${process.env.REACT_APP_LOCALE}.title`), 
            width: '15%',
      },
    ],
    []
  )

  return (
    <Paper>
      <CollectionHeader
        buttonTitle={translate('T_MODULE_ARTICLES_NEW_BUTTON')}
        buttonPath={routes().new.path}
      >
        {translate('T_MODULE_ARTICLES')}
      </CollectionHeader>
      <SchemableCollection
        component={CollectionTable}
        tableTitle={translate('T_MODULE_ARTICLES_LIST')}
        path={`paths.${schema.endpoint}.get`}
        endpoint={schema.endpoint}
        columns={columns}
        customResourceSchema={schema.resource}
        defaultSorters={{ ord: 'desc' }}
        orderable={true}
        selectable={[publish.on(), publish.off()]}
        isRowLinkable={true}
        editPath={routes().show.path}
        autoWidth={false}
      />
    </Paper>
  )
}
