
import {
  //text,
  textWithQuotation,
  textWithLink,
  //textWithSections,
  packageGallery,
} from 'core/_schema/_blocks'

// customowe typy bloczków
import {
    textWithSectionsLinks,
    textWithSectionsPhotos,
    text,
    packageGallerySimple,
    packageFileSimple,
    packageFaqSimple,
    textWithPhotoAndAlt,
    textWithFile,
    textWithTwoButtonLinks,
    textWithTwoLinks,

    audio,
    video,
    youtube,
    vimeo,
    logoKit,
    author,
    menu
} from './_blocks'

const schema = (
  photoEndpoint,
  fileEndpoint,
  sectionEndpoint,
  blockThumbEndpoint,
  sectionThumbEndpoint
) => ({
  types: [
    text(),
    textWithTwoButtonLinks(),
    textWithTwoLinks(),
    textWithPhotoAndAlt(photoEndpoint, blockThumbEndpoint),
    textWithFile(fileEndpoint),
    textWithSectionsLinks(photoEndpoint, sectionEndpoint, sectionThumbEndpoint),
    textWithSectionsPhotos(photoEndpoint, sectionEndpoint, sectionThumbEndpoint),
    textWithQuotation(),
    textWithLink(photoEndpoint, blockThumbEndpoint),
    //textWithSections(photoEndpoint, sectionEndpoint, sectionThumbEndpoint),

    author(photoEndpoint, blockThumbEndpoint),

    audio(photoEndpoint, fileEndpoint, blockThumbEndpoint),
    //video(photoEndpoint, fileEndpoint, blockThumbEndpoint),
    youtube(photoEndpoint, blockThumbEndpoint),
    vimeo(photoEndpoint, blockThumbEndpoint),

    packageFileSimple(),
    packageGallerySimple(),
    packageFaqSimple(),
    logoKit(),
    menu(),
  ],
})

export default schema